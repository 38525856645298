@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
}

body {
    /* background-color: #9a3131; */
    background-color: #f0f0f0;
    color: #0f0f0f;
    font-family: 'Assistant', sans-serif;

}

@keyframes slideFromTop {
    0% {
        transform: translateY(-500px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideToLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-500px);
    }
}

@keyframes slideFromBottom {
    0% {
        transform: translateY(500px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideFromRight {
    0% {
        transform: translateX(500px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideFromLeft {
    0% {
        transform: translateX(-500px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideFromTopReverse {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-1000px);
    }
}

@keyframes slideFromBottomReverse {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(1000px);
    }
}

@keyframes slideFromRightReverse {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(750px);
    }
}

.slide-from-top {
    transition: all;
    animation: slideFromTop 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

.slide-to-left {
    transition: all;
    animation: slideToLeft 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}
.slide-from-top-reverse {
    transition: all;
    animation: slideFromTopReverse 1s cubic-bezier(0.75, 0.885, 0.32, 1.275)
}

.slide-from-bottom {
    transition: all;
    animation: slideFromBottom 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

.slide-from-bottom-reverse {
    transition: all;
    animation: slideFromBottomReverse 1s cubic-bezier(0.75, 0.885, 0.32, 1.275)
}

.slide-from-right {
    transition: all;
    animation: slideFromRight 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

.slide-from-right-reverse {
    transition: all;
    animation: slideFromRightReverse 1s cubic-bezier(0.75, 0.885, 0.32, 1.275)
}

.slide-from-left {
    transition: all;
    animation: slideFromLeft 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

.trans-all {
    transition: all 0.3s ease-in-out;
}

.pricing-card-container {
    box-shadow: inset 4px 4px 2px rgba(100, 100, 100, 0.1), inset -4px -4px 2px rgba(0, 0, 0, 0.3);

}

.pricing-card {
    /* box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
        inset -5px -5px 15px rgba(255, 255, 255, 0.1),
        5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1); */
    box-shadow: inset -4px -4px 2px rgba(100, 100, 100, 0.1), inset 4px 4px 2px rgba(100, 100, 100, 0.1);
}
.pricing-card-selected {
    box-shadow: inset 4px 4px 2px rgba(46,133,64,0.8), inset -4px -4px 2px rgba(46,133,64,0.8);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.fade-in {
    transition: all;
    animation: fadeIn 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.height {
    height: 95vh;
}


@keyframes scaleToOne {
    0% {
        opacity: 0;
        transform: scale(0);
        display: none;
    }

    90% {
        display: none;
    }

    100% {
        opacity: 1;
        transform: scale(1);
        display: flex;
    }
}



.scaleToOne {
    transition: all;
    animation: scaleToOne 1s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    opacity: 0;
    transform: scale(0);
    display: none;
    animation-delay: 500ms;
}

.black {
    background-color: black;
}

.input-label {
    position: absolute;
    left: 2rem;
    transform: translate(-50%, -50%);
}

@keyframes alert-rightpt1 {
    0% {
        right: -150px;
        opacity: 0;
    }

    100% {
        right: 27px;
        opacity: 1;
    }
}

@keyframes alert-rightpt2 {
    0% {
        right: 27px;
        opacity: 1;
    }

    100% {
        right: 100%;
        opacity: 0;
    }
}

.alert {
    color: white;
    font-weight: 600;
    padding: 2px 8px 2px 8px;
    border-radius: 10px;
    position: absolute;
    opacity: 0;
    padding: 0.5rem;
    margin: auto;
    top: 2rem;
    transition: all;
    z-index: 999999;
    min-width: 300px;
    text-align: center;
    animation: alert-rightpt1 1s cubic-bezier(0.19, 1, 0.22, 1) 0s forwards, alert-rightpt2 1s cubic-bezier(0.19, 1, 0.22, 1) 2.5s forwards;
}

.success-alert {
    background: #2E8540;
}

.error-alert {
    background: #ff2222;
}

.warning-alert {
    background: #FDB81E;
}

.page-container {
    box-shadow: rgba(50, 50, 93, 0.2) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.sidebar-item:hover {
    box-shadow: rgba(250, 250, 250, 0.3) 0px 6px 12px -2px, rgba(200, 200, 200, 0.5) 0px 3px 7px -3px;
}

.signin-card {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.app-info {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
}


.sidebar {
    flex-direction: column;
    align-items: center;
    height: 95vh;
    transition: all 0.3s ease-in-out;
    background-color: black;
    box-shadow: rgba(50, 50, 93, 0.5) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 0.75rem;
    border-radius: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}


.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 0;
    min-width: 500px;
}
.desc-tooltip {
    opacity: 0;
    z-index: -50;
    transition: all 0.3s ease-in-out;
    transform: translateY(-50px);
    position: absolute;
    left: 0;
}

.desc-tooltip-trigger:hover > .desc-tooltip {
    transform: translateY(50px);
    opacity: 1;
    z-index: 9999999;
    background-color: white;
    color: black
}
/* #card:hover { transform: rotateY(180deg); }

#card > div:nth-child(1) { transition-delay: 150ms;}

#card:hover > div:nth-child(1) {
  opacity: 0;
  z-index: -1;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.str-video {
    background-color: black;
    color: #fff;
    height: 100dvh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    min-width: 0;
    max-width: 100%;
}

.get-started {
    background: -webkit-linear-gradient(#CFD3DC,#0f0f0f);  
    background: -moz-linear-gradient(#CFD3DC,#0f0f0f);  
    background: -o-linear-gradient(#CFD3DC,#0f0f0f);  
    background: linear-gradient(#CFD3DC,#0f0f0f);  
    color: black;
    -webkit-transition: all 0.81s ease-out;  
    -moz-transition: all 0.81s ease-out;  
    -o-transition: all 0.81s ease-out;  
    transition: all 0.81s ease-out;  

    background-size:1px 200px;  
}

.get-started:hover{  
    background-position:100px;  
    color: white;
} 

.sign-in {
    background: -webkit-linear-gradient(#CFD3DC,#000);  
    background: -moz-linear-gradient(#CFD3DC,#000);  
    background: -o-linear-gradient(#CFD3DC,#000);  
    background: linear-gradient(#CFD3DC,#000);  
    color: black;
    -webkit-transition: all 0.3s ease-out;  
    -moz-transition: all 0.3s ease-out;  
    -o-transition: all 0.3s ease-out;  
    transition: all 0.3s ease-out;  

    background-size:1px 200px;  
}

.sign-in:hover{  
    background-position:100px;  
    color: white;
} 